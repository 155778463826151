import moment from 'moment';
import {
  getCurrentDateRangePeriods,
  getTailingDropStartDate,
  getComparisonDateRangePeriods
} from '../../vizOptionsHelper';
import { getTailingDropStartDateQuarter } from '../../Helpers/projectionHelper';
import { apiDataHelper } from './apiDataHelper';
import { getYearTextByRange } from 'helpers/dateHelper';

export class LineChartOptionsHelper {
  constructor(rawApiData, vizOptions) {
    this.rawApiData = rawApiData;
    this.vizOptions = vizOptions;
    this.apiDataHelper = new apiDataHelper(rawApiData, vizOptions);
    this.apiData = this.apiDataHelper.formatApiData();
  }

  getCurrentPeriods() {
    const currentPeriods = getCurrentDateRangePeriods(this.vizOptions, this.apiData);
    return _.filter(currentPeriods, (period) => {
      return !period.isAfter(this.getTailingDropStartDate()) &&
        !period.isSame(this.getTailingDropStartDate());
    });
  }

  getComparePeriods() {
    return getComparisonDateRangePeriods(this.vizOptions);
  }

  getTailingDropStartDate() {
    const subtractValue = this.getTailingDropSubtractValue();
    return getTailingDropStartDateQuarter(
      getTailingDropStartDate(this.vizOptions).subtract(subtractValue, 'day'),
      this.vizOptions,
      true
    );
  }

  getTailingDropSubtractValue() {
    const { dateRange, axisGranularity } = this.vizOptions;
    const isSameMonth = moment(dateRange.startDate).isSame(dateRange.endDate, 'month');
    return (isSameMonth || axisGranularity === 'day') ? 0 : 1;
  }

  getFiscalYear() {
    const dataRangeEndDate = _.get(this.vizOptions, 'dateRange.endDate');
    return !_.isEmpty(dataRangeEndDate) ? moment(dataRangeEndDate).format('YYYY') : '';
  }

  getComparisonYear() {
    const compareYearRanges = _.get(this.vizOptions, 'compareYearRanges');
    return _.size(compareYearRanges) === 1
      ? getYearTextByRange(compareYearRanges[0], this.vizOptions.dateRangeMode)
      : null;
  }
}
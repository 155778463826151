import moment from 'moment';
import _ from 'lodash';
import pluralize from 'pluralize';
import {
  AXIS_GRANULARITY_TYPES,
  OVERTIME_TIME_FRAME_OPTIONS,
  OVERTIME_VISUALIZATION_TYPES,
  OVERTIME_DIMENSION_ORDER
} from 'appConstants';
import { disableMetricTotal } from 'common/config/viewConfiguration';
import { getDateRangeStartMonth } from 'common/config/customerConfiguration';
import { isShowRangeSlider, rangeSliderType } from 'common/config/visualizationConfiguration';
import {
  getBenchMarkEntries,
  getSecondaryMetricEntries
} from 'helpers/visualizationHelper';
import { getCurrentDrilldownDimensionColumn } from 'helpers/chartDataHelper';
import { getPeriodType, isQuarterPeriod, getCurrentDateRangePeriods } from '../vizOptionsHelper';
import { getCurrentTemplateEntry } from 'common/config/templateConfiguration';
import { defaultPrepareDataAxisGranularityOption } from 'pages/Forecasting/ForecastHelper';
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const getAxisGranularityOptions = (templateId, without_default = false) => {
  const templateEntry = getCurrentTemplateEntry(templateId);

  let axisGranularityOptions = _.get(templateEntry, `axis_granularity_options`, []);

  const yearConfig = _.find(axisGranularityOptions, { value: 'year'});
  if(yearConfig) {
    axisGranularityOptions = _.filter(axisGranularityOptions, ({ value }) => {
      return (value !== 'year');
    })
    axisGranularityOptions.push(yearConfig);
  }

  if (_.size(axisGranularityOptions) > 1 && !without_default) {
    axisGranularityOptions.unshift(AXIS_GRANULARITY_TYPES[0]);
  }

  return _.uniqBy(axisGranularityOptions, 'value');
}

export const getDefaultAxisGranularity = (templateId) => {
  const axisGranularityOptions = getAxisGranularityOptions(templateId);

  if (_.size(axisGranularityOptions) > 1) {
    return showAllYears() ?
      _.get(axisGranularityOptions, '0.value') :
      AXIS_GRANULARITY_TYPES[0].value;
  } else {
    return _.get(axisGranularityOptions, [0, 'value'], AXIS_GRANULARITY_TYPES[0].value);
  }
}

export const getOvertimeDimensionSortOrder = (viewEntry, renderType) => {
  return _.get(viewEntry,
    `visualization.overtime.${renderType}.default_dimension_sort`);
}

export const showOvertimeChartDimensions = (viewEntry, renderType) => {
  const showDimensions = _.get(viewEntry,
    `visualization.overtime.${renderType}.show_dimensions`) === 'true';
  return showDimensions;
}

export const shouldShowDimensions = (viewEntry, renderType) => {
  return showOvertimeChartDimensions(viewEntry, renderType) || disableMetricTotal(viewEntry);
}

export const defaultNumberOfdimensions = (viewEntry, renderType) => {
  const dimensionLimit = _.get(viewEntry,
    `visualization.overtime.${renderType}.default_no_of_dimensions`, 5);
  return dimensionLimit;
}

export const isDimensionSortOrderHightToLow = (viewEntry, renderType) => {
  const dimensionOrderHightToLow = _.get(viewEntry,
   `visualization.overtime.${renderType}.default_dimension_sort`, OVERTIME_DIMENSION_ORDER.HIGH_TO_LOW.type);
  return dimensionOrderHightToLow === OVERTIME_DIMENSION_ORDER.HIGH_TO_LOW.type;
}

export const showAllYears = () => {
  return isShowRangeSlider() && rangeSliderType() == 'all_years';
}

export const shouldDisableCompareDropDown = (options, isRolling = true) => {
  const {
    currentSelectedTimeFrame,
    isComboChart,
    currentChartView,
    isComparisonEnabled
  } = options;

  const isAreaChart = _.isEqual(OVERTIME_VISUALIZATION_TYPES.AREA.type, currentChartView);
  const isRollingTimeFrame = _.isEqual(currentSelectedTimeFrame, OVERTIME_TIME_FRAME_OPTIONS.ROLLING);

  if ((isRollingTimeFrame && isRolling) || isComboChart || isAreaChart || !isComparisonEnabled) {
    return true;
  } else {
    return false;
  }
}

export const getQuarterMonths = () => {
  const month = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const startMonth = getDateRangeStartMonth();
  const quarterSplitMonth = 3;
  const monthWithQuarterArray = {};

  const beforeStartMonths = _.take(month, startMonth);
  const afterStartMonths = _.drop(month, startMonth);
  var newMonths = [...afterStartMonths, ...beforeStartMonths];

  const chunkedArray = _.chunk(newMonths, quarterSplitMonth)
  _.each(chunkedArray, (arrayItem, index) => {
    _.each(arrayItem, (item) => {
      monthWithQuarterArray[item] = `Q${index + 1}`
    });
  });

  return monthWithQuarterArray;
}

export const getGroupByQuarterData = (formattedData, ignoreFillGaps, isDummy, ignoreCurrentQuarter ) => {
  const formattedDataItems = ignoreFillGaps ?  formattedData :
                            formatDataByFillingGapPeriods(formattedData, ignoreCurrentQuarter)
  const quarterMonths = getQuarterMonths();
  const groupedQuarterData = _.groupBy(formattedDataItems, (formattedDataItem) => {
    const { period } = formattedDataItem;
    const periodMonth = moment(period).month();
    const periodYear = moment(period).year();
    const configMonth = getDateRangeStartMonth();
    let newStartYear = periodYear;
    if (configMonth > 0) {
      newStartYear = configMonth > periodMonth ? periodYear : Number(periodYear) + 1;
    }

    return `${quarterMonths[periodMonth]} ${newStartYear}`;
  });
  const data = _.map(groupedQuarterData, (groupedQuarterDatum, category) => {
    const firstQuarterData = _.first(groupedQuarterDatum);
    const isIgnoreSumValue = _.get(firstQuarterData, 'ignoreProjection', false);
    const secondaryTotal = _.get(firstQuarterData, 'secondary_total');
    const splittedCategory = category.split(' ');
    const categoryYear = _.get(splittedCategory, '1');
    const replacedIndex = _.get(splittedCategory, '0', '').replace('Q', '');

    const option = {
      ...firstQuarterData,
      'period': _.get(firstQuarterData, 'period'),
      'quarterText': category,
      'value': isIgnoreSumValue ? _.get(firstQuarterData, 'value') :
        _.sumBy(groupedQuarterDatum, ({ value }) => Number(value)),
      categoryIndex: Number(categoryYear + replacedIndex)
    };
    if (secondaryTotal) {
      option['secondary_total'] = isIgnoreSumValue ? _.get(firstQuarterData, 'secondary_total') :
       _.sumBy(groupedQuarterDatum,
        ({ secondary_total }) => Number(secondary_total)
      );
    }
    if (isDummy) {
      option['value'] = null;
      option['periodLabel'] = category;
    }
    return option;
  });

  return _.sortBy(data, ({ categoryIndex }) => Number(categoryIndex || 0));
}

export const shouldDisableAxisGranularity = (props) =>{
  const {
    isComboChart,
    drilldownTemplateId
  } = props;
  const axisGranularityOptions = getAxisGranularityOptions(drilldownTemplateId);
  const showRangeSlider = isShowRangeSlider(isComboChart)
  if(isComboChart && _.size(axisGranularityOptions) >= 1){
    return false;
  }
  return !showRangeSlider || _.size(axisGranularityOptions) <= 1;
}

export const shouldDisableSecondaryMetrics = (props) => {
  const {
    currentChartView,
    currentDrilldownViewEntry,
    isComboChart
  } = props;

  const secondaryMetricEntries = getSecondaryMetricEntries(
    currentDrilldownViewEntry, 'overtime', currentChartView
  );
  return _.isEmpty(secondaryMetricEntries) || isComboChart;
}

export const shouldDisableBenchmarkOptions = (props) => {
  const { renderType, currentChartView } = props;
  const currentView = _.isUndefined(renderType) ? currentChartView : renderType;
  const benchmarkOptions = getCurrentDimensionBenchMarkEntries(props)
  return _.isEmpty(benchmarkOptions) || _.isEqual(OVERTIME_VISUALIZATION_TYPES.AREA.type, currentView);
}

export const getCurrentDimensionBenchMarkEntries = (props) => {
  const {
    visualizationType,
    renderType,
    currentChartView,
    drilldownDimensionField,
    drilldownTemplateId,
    viewEntry,
    isDiscrete,
    isCumulative,
    quickFilters
  } = props;
  const currentView = _.isUndefined(renderType) ? currentChartView : renderType;
  const options = { isCumulative, isDiscrete, quickFilters };

  const configuredBenchMarkEntries = getBenchMarkEntries(
    viewEntry,
    visualizationType,
    currentView,
    options
  );

  const dimensionColumn = getCurrentDrilldownDimensionColumn(drilldownTemplateId, drilldownDimensionField);
  return _.filter(configuredBenchMarkEntries, (entry) => {
    return (entry.dimension_column === dimensionColumn || _.isEmpty(entry.dimension_column))
  });
}

export const isOvertimeChartOptionsAreEmpty = (props) => {
  return shouldDisableAxisGranularity(props) &&
    shouldDisableSecondaryMetrics(props) &&
    shouldDisableBenchmarkOptions(props)
}

const formatDataByFillingGapPeriods = function (formattedDataItems, ignoreCurrentQuarter = false) {
  let prevPeriodMoment;
  let formattedData = [];

  // Determine the current quarter
  const currentQuarter = moment().quarter();
  const currentQuarterStart = moment().quarter(currentQuarter).startOf('quarter');
  const currentQuarterEnd = moment().quarter(currentQuarter).endOf('quarter');

  _.forEach(formattedDataItems, (datum, index) => {
    const { period } = datum;

    if (index == 0) {
      prevPeriodMoment = moment(period);
    }

    const periodMoment = moment(period);

    if (ignoreCurrentQuarter) {
      if (!periodMoment.isBetween(currentQuarterStart, currentQuarterEnd, 'day', '[]')) {
        formattedData.push(datum);
      }
    } else {
    if(index > 0 && periodMoment.diff(prevPeriodMoment, 'month') > 1) {
      let periodList = [];
      const addMonths = periodMoment.diff(prevPeriodMoment, 'month');
      let periodDummyDate = prevPeriodMoment;

      for (let periodIndex = 1; periodIndex < addMonths; periodIndex++) {
        periodDummyDate = periodDummyDate.add(1, 'M');
        periodList.push(_.cloneDeep(periodDummyDate));
      }

      const newDummyData = getDummyPeriodGapData(periodList)
      formattedData = formattedData.concat(newDummyData);
      formattedData.push(datum);
    } else {
      formattedData.push(datum);
    }
  }
    prevPeriodMoment = moment(period);

  });

  return formattedData;
}

const getDummyPeriodGapData = function (periods) {
  return _.map(periods, (period) => {

    // Filling Gaps
    return {
      period: period.format(DATE_FORMAT),
      periodMmt: period,
      value: null,
      secondary_total: null
    };
  });
}

export const updateAdjustedValueForData = (apiTotalData, vizOptions, uniqueId) => {
  const { projectionAdjustedValues, axisGranularity, templateId } = vizOptions;
  const defaultAxisGranularity = defaultPrepareDataAxisGranularityOption(templateId);
  const isOnMinimumGranularity = axisGranularity == defaultAxisGranularity;
  // Adding Gap in Total, To connect lines when prepare data is added.
  if(isOnMinimumGranularity) {
    _.each(projectionAdjustedValues, (adjustedEntry) => {
      if(adjustedEntry['value'] == 0 && Number(adjustedEntry['adjustValue']) > 0) {
        apiTotalData.push({
          period: moment(adjustedEntry['period'], DATE_FORMAT).format('YYYY-MM-DD'),
          value: 0
        });
      }
    });
  }

  const periodType = isQuarterPeriod(vizOptions) ? 'quarters' : getPeriodType(vizOptions);
  const periods = getCurrentDateRangePeriods(vizOptions, {total: []}, true);

  _.forEach(apiTotalData, (datum) => {
    const periodMmt =  moment(datum.period).startOf('day');
    const currentPeriod = _.find(periods, (period) => {
      return periodMmt.isBetween(period, period.endOf(periodType), periodType, '[]');
    });
    if(_.isEmpty(currentPeriod)) {
      return;
    }
    let withInPeriodItems = _.filter(projectionAdjustedValues, (dataItem) => {
      const itemPeriodMmt =  moment(dataItem.period).startOf('day');
      return (
        (itemPeriodMmt.isBetween(currentPeriod, currentPeriod.endOf(periodType), periodType, '[]') ||
          moment(dataItem.period).isSame(currentPeriod, periodType)
        )&&
        dataItem['uniqueId'] == uniqueId
        );
    });
    let adjustValue = 0, accumulatedValue = 0;
    const availableDataItems = _.filter(withInPeriodItems, (item) => {
      return !_.isEmpty(item['adjustValue']+'');
    });

    let noOfNotes = 0;
    _.each(withInPeriodItems, (item) => {
        adjustValue += Number(item['adjustValue'] || item['value']);
        accumulatedValue += Number(item['accumulateValue']);
        noOfNotes += _.isEmpty(item['note']) ? 0 : 1;
    });

    if (!_.isEmpty(availableDataItems)){
      datum['adjustValue'] = adjustValue;
      datum['note'] = getNotes(withInPeriodItems, noOfNotes, isOnMinimumGranularity);
      datum['isAccumulated'] = _.get(availableDataItems, '[0].isAccumulated');
      datum['accumulateValue'] = accumulatedValue;
    } else {
      datum['note'] = getNotes(withInPeriodItems, noOfNotes, isOnMinimumGranularity);
    }
  });
}

const getNotes = (withInPeriodItems, noOfNotes, isOnMinimumGranularity) => {
  if (isOnMinimumGranularity) {
    return _.get(withInPeriodItems, '[0].note', '');
  } else {
    return noOfNotes > 0 ?
      `(${noOfNotes} ${pluralize('note', noOfNotes)}; Change granularity to see)` : '';
  }
}

export const getValidChartValue = (datum) => {
  const isAccumulated = _.get(datum, 'isAccumulated', false);
  let value = Number(_.get(datum, 'value', '0'));
  const accumulateValue = Number(_.get(datum, 'accumulateValue', '0'));
  return isAccumulated ? (accumulateValue || 0) : (value || 0);
}

const getBurnUpChartValue = (datum) => {
  const value = Number(_.get(datum, 'value', 0));
  const adjustValue = Number(_.get(datum, 'adjustValue', 0));
  return adjustValue == 0 ? value : adjustValue;
}

export const getAdjustedDataAndNegativeData = (formattedDataTotal, vizOptions) => {
  const { templateId, renderType } = vizOptions;
  const isBurnUp = renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
  const adjustFormatted = _.filter(formattedDataTotal, (datum) => {
    const value = getValidChartValue(datum);
    datum['value'] = value;

    const adjustValue = Number(_.get(datum, 'adjustValue'));
    return adjustValue > 0
  });

  const defaultAxisGranularity = defaultPrepareDataAxisGranularityOption(templateId);
  const filterStartDate = moment().startOf(defaultAxisGranularity);
  let accumulatedValue = 0;
  const negativeAndGapFormatted = _.filter(formattedDataTotal, (datum, index) => {
    const value = isBurnUp ? getBurnUpChartValue(datum) : getValidChartValue(datum);
    let isNegativeValue = value <= 0
    if (isBurnUp) {
      accumulatedValue = (index == 0 && accumulatedValue == 0) ?
      value : accumulatedValue;
      if (index != 0){
        accumulatedValue = accumulatedValue + (value || 0);
      }
      datum['accumulateValue'] = accumulatedValue;
      isNegativeValue = accumulatedValue <= 0
    }

    const adjustValue = Number(_.get(datum, 'adjustValue', 0));
    const isAdjustValue = adjustValue > 0;
    const isValidPeriod = moment(datum.period) < filterStartDate;
    let isNegativeAdjustValue = adjustValue < 0;
    datum['value'] = value;
    if (isBurnUp && index > 0){
      datum['adjustValue'] = accumulatedValue;
      isNegativeAdjustValue = accumulatedValue <= 0
    }

    return ( isNegativeValue && !isAdjustValue && isValidPeriod) || (isNegativeAdjustValue)
  });

  return { adjustFormatted, negativeAndGapFormatted}
}
import moment from 'moment';
import {
  OVERTIME_VISUALIZATION_TYPES,
  DEFAULT_TOTAL_NAME,
  DATE_TIME_FORMAT,
  PROPHET_FORECAST_COLOR,
  EXPONENTIAL_FORECAST_COLOR,
  FORECASTING_TYPE,
  FORECAST_MODEL_TYPES
} from 'appConstants';
import { VIEW_MODE } from 'modules/visualization/constants';
import {
  getPeriodType,
  isTraceVisible,
  getTailingDropStartDate,
  getFeb28Entries,
  findYearIndex,
  extractYearsBetweenDates,
  generateYearsList
} from '../vizOptionsHelper';
import { getFormattedWeekPeriod } from 'helpers/dateHelper';
import { disableShowProjection, getTailingDropStartDateQuarter } from '../Helpers/projectionHelper';
import {
  updateAdjustedValueForData,
  getAdjustedDataAndNegativeData
} from '../Helpers/overtimeHelper';
import { isDimensionsChange } from '../legendHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { isDiscreteModeData } from 'common/config/viewConfiguration';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';
import { isTimeDurationEnabled, timeDurationDataUnit } from 'common/config/customerConfiguration';
import { getSecondsAsDuration, getSecondsAsDurationHours } from 'helpers/visualizationHelper';
import { getValueKey } from '../Helpers/projectionDataGenerator';

export const toPlotlyTraces = (vizOptions, formattedData) => {
  const {
    templateId,
    viewMode,
    renderType,
    projectionType,
    viewEntry,
    dateRange,
    renderTimeFrame,
    axisGranularity,
    projectionEnabled,
    secondaryMetricEntry } = vizOptions;
  const isSmallView = viewMode === VIEW_MODE.SMALL;
  const isBurnup = renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
  const isDiscreteMode = isDiscreteModeData(viewEntry);
  const periodType = getPeriodType(vizOptions);
  const nullValueLabel = getNullValueLabel(templateId);
  const currentYear = _.get(formattedData, 'current.year');
  let totalTraceColor = getConfiguredMetricColor(templateId, 'primary');
  const tailingDropStartDate = getTailingDropStartDateQuarter(
    getTailingDropStartDate(vizOptions), vizOptions);
  let dimensionConfigs = _.get(vizOptions, 'dimensionConfigs', []);
  if (_.isEmpty(dimensionConfigs)) {
    dimensionConfigs = _.get(formattedData, 'dimensionConfigs', []);
  }

  const dimensionConfig = _.find(dimensionConfigs, {isTotalLine : true, traceId: DEFAULT_TOTAL_NAME});
  totalTraceColor = _.get(dimensionConfig, 'color', totalTraceColor);

  const totalMeta = {
    segmentType: 'current',
    isTotal: true,
    dimension: 'Total',
    year: currentYear,
    color: totalTraceColor,
    tailingDropStartDate
  };

  const shouldShowProjections = !disableShowProjection(
    dateRange,
    formattedData,
    renderTimeFrame,
    viewEntry,
    axisGranularity
  );
  const isDimensionsChanged = isDimensionsChange(formattedData, vizOptions);
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const dataUnit = timeDurationDataUnit(viewEntry);
  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const secondaryDataUnit = timeDurationDataUnit(secondaryMetricEntry);

  const traces = [
    ...getTraceForSegment(vizOptions, formattedData, 'current', 'primary')
  ]

  if (!_.isEmpty(secondaryMetricEntry)) {
    traces.push(getTraceForSegment(vizOptions, formattedData, 'current', 'secondary'));
    traces.push(getTraceForSegment(vizOptions, formattedData, 'tailingDrop', 'secondary'));
  }

  const isForecastingView = _.get(vizOptions, 'isForecastingView', false);
  const isProjection = true;
  const commonTraceOptions = {
    lineWidth: 2, isBurnup, isDiscreteMode, periodType, isSmallView, projectionType,
    dateRange, axisGranularity,
    isProjectionEnabled: projectionEnabled,
    isForecastingView
  }

  if (isForecastingView) {
    const forecastingOptions = {
      totalTraceColor,
      traces,
      formattedData,
      totalMeta,
      commonTraceOptions,
      isProjection,
      vizOptions,
      isDimensionsChanged,
      isSecondsFormat,
      dataUnit,
      isForecastingView,
      secondaryMetricEntry,
      isSecondsFormatSecondary,
      secondaryDataUnit
    }
    const markerOptions = {
      vizOptions,
      totalTraceColor,
      totalMeta,
      dataUnit,
      isProjection,
      isSecondsFormat,
      isDimensionsChanged,
      commonTraceOptions
    }

    getNegativeAndAdjustMarker(traces, formattedData, markerOptions, isBurnup);

    if (!projectionEnabled && (['week', 'quarter'].includes(_.get(vizOptions, 'axisGranularity')))) {
        getDummyXAxisForecast(traces, formattedData, markerOptions);
    }
    if (projectionEnabled){
      traces.push(getTraceForSegment(vizOptions, formattedData, 'actualDropItems', 'primary'));
      advanceForecastingProjections(forecastingOptions);
    }
  }
  traces.push(getTraceForSegment(vizOptions, formattedData, 'tailingDrop', 'primary'));
  // Projection Lines
  if ((projectionEnabled && shouldShowProjections)) {
      totalMeta['color'] = isForecastingView ? PROPHET_FORECAST_COLOR : totalTraceColor;
      const totalLastCurrentItem = isForecastingView ?
        _.first(formattedData.projection.total_last_current) : {};
      const {showTotalTrace, totalColor} = totalLineOptions(vizOptions, formattedData, totalTraceColor);
      if (showTotalTrace){
        traces.push(toLineTrace(formattedData.projection.total, 'value', {
          meta: _.merge({}, totalMeta, { value: 'primary', isProjection }),
          lineColor: isForecastingView ? PROPHET_FORECAST_COLOR : totalColor,
          lineDash: 'dot',
          visible: isTraceVisible(vizOptions, { traceId: DEFAULT_TOTAL_NAME, isDimensionsChanged }),
          isSecondsFormat,
          dataUnit,
          totalLastCurrentItem,
          ...commonTraceOptions
        }));
      }

      if (!_.isEmpty(secondaryMetricEntry)) {
        traces.push(toLineTrace(formattedData.projection.total, 'secondary_total', {
          meta: _.merge({}, totalMeta, { value: 'secondary', isProjection }),
          lineColor: isForecastingView ? PROPHET_FORECAST_COLOR : totalTraceColor,
          lineDash: 'dot',
          visible: isTraceVisible(vizOptions, { traceId: DEFAULT_TOTAL_NAME, isDimensionsChanged }),
          isSecondsFormat: isSecondsFormatSecondary,
          dataUnit: secondaryDataUnit,
          ...commonTraceOptions
        }));
      }
    _.each(formattedData.projection.entries, (dataItems) => {
      const dimension = _.get(dataItems, '[0].dimension');
      const traceId = _.isEmpty(dimension) ? nullValueLabel : dimension;
      const color = _.get(_.find(dimensionConfigs, { traceId }), 'color');
      const meta = {
        segmentType: 'current',
        dimension: traceId,
        year: currentYear,
        color,
        isProjection
      };

      traces.push(toLineTrace(dataItems, 'value', {
        meta: _.merge({}, meta, { value: 'primary' }),
        lineColor: color,
        lineDash: 'dot',
        visible: isTraceVisible(vizOptions, { traceId }),
        isSecondsFormat,
        dataUnit,
        ...commonTraceOptions
      }));
      if (!_.isEmpty(secondaryMetricEntry)) {
        traces.push(toLineTrace(dataItems, 'secondary_total', {
          meta: _.merge({}, meta, { value: 'secondary' }),
          lineColor: color,
          lineDash: 'dashdot',
          visible: isTraceVisible(vizOptions, { traceId }),
          isSecondsFormat: isSecondsFormatSecondary,
          dataUnit: secondaryDataUnit,
          ...commonTraceOptions
        }));
      }
    });
  }
  return _.flatten(traces);
};

const advanceForecastingProjections = (options) => {
  const {
    totalTraceColor,
    traces,
    formattedData,
    totalMeta,
    commonTraceOptions,
    isProjection,
    vizOptions,
    isDimensionsChanged,
    isSecondsFormat,
    dataUnit,
    isForecastingView,
    secondaryMetricEntry,
    isSecondsFormatSecondary,
    secondaryDataUnit
  } = options;
  _.each(formattedData.projection.forecastingProjections, (forecastProjection) => {
    let projectionData = _.cloneDeep(formattedData.projection.total_last_current);

    const projection = _.get(forecastProjection, 'data', []);
    const projectionType = _.get(forecastProjection, 'type', '');
    const defaultProjectionName = _.find(FORECAST_MODEL_TYPES, { type: projectionType })['name'];
    const projectionName = _.get(forecastProjection, 'name', defaultProjectionName);
    const projectionColor = _.get(forecastProjection, 'color', totalTraceColor);
    // Updated unique dimension name to support duplicate historical models
    const dimension = (projectionType === FORECASTING_TYPE.HISTORICAL_AVG) ?
                        projectionName+projectionColor : projectionName;

    projectionData.push(_.first(projection));
    traces.push(toLineTrace(projectionData, 'value', {
      meta: _.merge({}, totalMeta, { value: 'primary', isProjection, dimension, projectionName}),
      lineColor: totalTraceColor,
      lineDash: 'dot',
      visible: isTraceVisible(vizOptions, { traceId: DEFAULT_TOTAL_NAME, isDimensionsChanged }),
      isSecondsFormat,
      dataUnit,
      ...commonTraceOptions
    }));
    let traceColor;
    if(projectionType == FORECASTING_TYPE.PROPHET){
      traceColor = PROPHET_FORECAST_COLOR
    }else if(projectionType == FORECASTING_TYPE.SIMPLE_EXPONENTIAL){
      traceColor = EXPONENTIAL_FORECAST_COLOR
    }else{
      traceColor = projectionColor
    }

    totalMeta['color'] = traceColor;
    const totalLastCurrentItem = isForecastingView ?
      _.first(formattedData.projection.total_last_current) : {};

    traces.push(toLineTrace(projection, 'value', {
      meta: _.merge({}, totalMeta, { value: 'primary', isProjection, dimension, projectionName }),
      lineColor: traceColor,
      lineDash: 'dot',
      visible: isTraceVisible(vizOptions, { traceId: DEFAULT_TOTAL_NAME, isDimensionsChanged }),
      isSecondsFormat,
      dataUnit,
      totalLastCurrentItem,
      ...commonTraceOptions
    }));

    if (!_.isEmpty(secondaryMetricEntry)) {
      traces.push(toLineTrace(projection, 'secondary_total', {
        meta: _.merge({}, totalMeta, { value: 'secondary', isProjection, dimension, projectionName }),
        lineColor: traceColor,
        lineDash: 'dot',
        visible: isTraceVisible(vizOptions, { traceId: DEFAULT_TOTAL_NAME, isDimensionsChanged }),
        isSecondsFormat: isSecondsFormatSecondary,
        dataUnit: secondaryDataUnit,
        ...commonTraceOptions
      }));
    }
  });
};

const getTraceForSegment = (vizOptions, formattedData, segmentType, lineType) => {
  const { templateId, viewMode, renderType, dateRange,
    projectionEnabled, viewEntry, secondaryMetricEntry, axisGranularity } = vizOptions;
  const nullValueLabel = getNullValueLabel(templateId);
  const isSmallView = viewMode === VIEW_MODE.SMALL;
  const isBurnup = renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
  const isDiscreteMode = isDiscreteModeData(viewEntry);
  const periodType = getPeriodType(vizOptions);
  const currentYear = _.get(formattedData, 'current.year');
  const totalMeta = { segmentType: 'current', isTotal: true, dimension: 'Total', year: currentYear };
  const lineDash = lineType == 'secondary' ? 'dashdot' : null;
  const valueFiled = lineType == 'secondary' ? 'secondary_total' : 'value';
  const dataSegment = _.get(formattedData, segmentType, {});
  const renderSegmentType = segmentType == 'tailingDrop' || segmentType == 'actualDropItems' ? 'markers' : '';
  const totalTraceColor = getConfiguredMetricColor(templateId, 'primary');
  const traces = [];
  const isDimensionsChanged = isDimensionsChange(formattedData, vizOptions);
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const dataUnit = timeDurationDataUnit(viewEntry);
  const secondaryDataUnit = timeDurationDataUnit(secondaryMetricEntry);
  const isDisplayTime = lineType == 'secondary' ? isSecondsFormatSecondary : isSecondsFormat;
  const displayDataUnit = lineType == 'secondary' ? secondaryDataUnit : dataUnit;
  const isForecastingView = _.get(vizOptions, 'isForecastingView', false);
  const commonTraceOptions = {
    lineWidth: (segmentType == 'comparison') ? 1 : 2,
    lineDash, isBurnup, isDiscreteMode, periodType, axisGranularity,
    renderType: renderSegmentType, isSmallView,
    dateRange,
    isProjectionEnabled: projectionEnabled,
    isForecastingView
  }
  let dimensionConfigs = _.get(vizOptions, 'dimensionConfigs', []);
  if (_.isEmpty(dimensionConfigs)) {
    dimensionConfigs = _.get(formattedData, 'dimensionConfigs', []);
  }

  const {showTotalTrace, totalColor} = totalLineOptions(vizOptions, formattedData, totalTraceColor);

  if(showTotalTrace){
    traces.push(toLineTrace(dataSegment.total, valueFiled, {
      meta: _.merge({}, totalMeta, { value: lineType, color: totalColor }),
      lineColor: totalColor,
      visible: isTraceVisible(vizOptions, {
        traceId: DEFAULT_TOTAL_NAME, isDimensionsChanged, isTotalLine: true
      }),
      isSecondsFormat: isDisplayTime,
      dataUnit: displayDataUnit,
      vizOptions,
      ...commonTraceOptions
    }));
  }

  if (!isForecastingView) {
    _.each(dataSegment.entries, (dataItems) => {
      const dimension = _.get(dataItems, '[0].dimension');
      const traceId = _.isEmpty(dimension) ? nullValueLabel : dimension;
      const color = _.get(_.find(dimensionConfigs, { traceId }), 'color');

      traces.push(toLineTrace(dataItems, valueFiled, {
        meta: { segmentType: 'current', dimension: traceId, value: lineType, year: currentYear, color },
        lineColor: color,
        visible: isTraceVisible(vizOptions, { traceId }),
        isSecondsFormat: isDisplayTime,
        dataUnit: displayDataUnit,
        vizOptions,
        ...commonTraceOptions
      }));
    });
  }
  return _.flatten(traces)
}

export const toLineTrace = (dataItems, valueKey = 'value', options = {}) => {
  const {
    isYearOnYear, isSecondsFormat, dataUnit,
    isProjectionEnabled, projectionType, dateRange,
    totalLastCurrentItem, isForecastingView,
    segmentType, axisGranularity, periodType
  } = options;
  let accumulatedValue = 0, accumulatedTextValue = 0;
  const { startDate, endDate } = dateRange;
  const yearRange = _.get(options,'yearRange');
  const yearsListfromDimension = (isYearOnYear && periodType == 'year') ?
    generateYearsList(yearRange, dataItems): [];
  const canAccumulateTotal = (options.isBurnup && options.isDiscreteMode);
  const initialTotalValue = isForecastingView ? Number(_.get(totalLastCurrentItem, 'value', 0)) : 0;

  const valueKeyField = 'adjustValue';

  const y = _.map(dataItems, (dataItem, index) => {

    const isAdjustedValue = isForecastingView ? getValueKey(dataItem) == valueKeyField : false;

    let value = _.isNull(dataItem[valueKey]) ? null : Number(dataItem[valueKey]);
    value = (isForecastingView && _.isNaN(value)) ? 0 : value;
    const adjustedValue = _.isNull(dataItem[valueKeyField]) ? null : Number(dataItem[valueKeyField]);

    value = isAdjustedValue ? adjustedValue : value;

    if (_.isNull(value) && accumulatedValue === 0) {
      return isSecondsFormat ? getSecondsAsDurationHours(value, dataUnit) : value || 0;
    } else {

      accumulatedValue = (index == 0 && isForecastingView && accumulatedValue == 0) ?
        initialTotalValue : accumulatedValue;

      accumulatedValue = accumulatedValue + (value || 0);

      let calcValue = (canAccumulateTotal) ? accumulatedValue :
        (isAdjustedValue ? adjustedValue : value);

      if (isSecondsFormat) {
        calcValue = getSecondsAsDurationHours(calcValue, dataUnit);
      }
      return calcValue;
    }
  });

  const text_values = _.map(dataItems, (dataItem, index) => {

    const isAdjustedValue = isForecastingView ? getValueKey(dataItem) == valueKeyField : false;
    let value = _.isNull(dataItem[valueKey]) ? null : Number(dataItem[valueKey]) || 0;
    value = (isForecastingView && _.isNaN(value)) ? 0 : value;

    const adjustedValue = _.isNull(dataItem[valueKeyField]) ? null : Number(dataItem[valueKeyField]) || 0;
    value = isAdjustedValue ? adjustedValue : value;

    if (_.isNull(value) && accumulatedTextValue === 0) {
      value = isSecondsFormat ? getSecondsAsDuration(value, dataUnit) : value || 0;
    } else {
      accumulatedTextValue = (index == 0 && isForecastingView && accumulatedTextValue == 0) ?
        initialTotalValue : accumulatedTextValue;
        accumulatedTextValue = accumulatedTextValue + (value || 0);
      let calcValue = (canAccumulateTotal) ? accumulatedTextValue :
        (isAdjustedValue ? adjustedValue : value);
      if (isSecondsFormat) {
        calcValue = getSecondsAsDuration(calcValue, dataUnit);
      }
      value = calcValue || 0;
    }
    return value;
  });

  const isQuarterType = axisGranularity == 'quarter';

  const dateRangeYears = extractYearsBetweenDates(startDate,endDate);

  let yearsInBetween = [];
  if(periodType == 'month' && isQuarterType){
    yearsInBetween = _.uniq(_.map(dataItems, item => _.parseInt(item.quarterText.split(" ")[1])));
  }else{
    const firstDate = _.get(_.first(dataItems),"period");
    const lastDate = _.get(_.last(dataItems),"period");
    yearsInBetween = extractYearsBetweenDates(firstDate,lastDate);
  }

  if(_.includes(["tailingDrop","tailingDropComparison","projection"],segmentType)){
    yearsInBetween = _.sortBy(_.union(dateRangeYears, yearsInBetween));
  }

  let weekPeriodLabel = [];
  const x = _.map(dataItems, (dataItem) => {
    let yearIndex = 0;
    if(periodType == 'month' && isQuarterType){
      const quarterYear = dataItem.quarterText.split(" ")[1];
      const date = moment(quarterYear);
      yearIndex = findYearIndex(yearsInBetween,date);
    }else{
      yearIndex = findYearIndex(yearsInBetween,dataItem.period);
    }

    // chart wont accept duplicate xaxis values(month),
    // so we adding extra space at end

    let extraSpaceForYearLabel = _.times(yearIndex, () => '').join(' ');
    extraSpaceForYearLabel = yearIndex >= 1 ? extraSpaceForYearLabel + ' ' : extraSpaceForYearLabel;

    if (isYearOnYear) {
      if(periodType == 'month' && !isQuarterType){
        return (moment(dataItem.period).format('MMM') + extraSpaceForYearLabel);
      }else if(periodType == 'year'){
        const year = moment(dataItem.period).format('YYYY');
        const index = _.indexOf(yearsListfromDimension, _.toInteger(year));
        const yearText = index >= 0 ?  `Year ${index+1}` : year ;
        return yearText;
      }else if(periodType == 'day'){
        return (moment(dataItem.period).format("MMM DD") + extraSpaceForYearLabel);
      }else if(periodType == 'month' && isQuarterType){
        return (_.split(dataItem.quarterText," ")[0] + extraSpaceForYearLabel);
      }else{
        return new Date(moment(dataItem.period).format(DATE_TIME_FORMAT));
      }
    } else {
      if(periodType == 'week' || isQuarterType){
        const weekLabel  = getWeekOrQuarterLabelByPeriod(dataItem, options);
        weekPeriodLabel.push(weekLabel);
        dataItem['periodLabel'] = weekLabel;
      }
      const periodLabel = getXaxisLabelByPeriod(dataItem, options);
      return periodLabel;
    }
  });

  // add Feb 29 data if the daterange includes leap year
  if(isYearOnYear && periodType == 'day'){
    const feb28Entries = getFeb28Entries(dataItems);
    if(!_.isEmpty(feb28Entries)){
      _.each(feb28Entries, (entry) => {
        const feb28Index = dataItems.findIndex(item => {
          return item.period === entry.period;
        });

        if(!moment(entry.period).isLeapYear()){
          const value = _.get(x,feb28Index);
          x.splice(feb28Index + 1, 0, _.replace(value, '8', '9'));
          y.splice(feb28Index + 1, 0, null);
        }
      });
    }
  }

  let trace;
  if (_.get(options, 'renderType') === 'bar') {
    trace = {
      type: 'bar',
      meta: _.get(options, 'meta', {}),
      orientation: 'v',
      customData: dataItems,
      marker: {
        color: _.get(options, 'lineColor', ''),
        line: {
          color: _.get(options, 'borderLineColor', ''),
          width: _.get(options, 'borderWidth', 2)
        }
      }
    }
  } else if (_.get(options, 'renderType') === 'markers') {
    trace = {
      marker: {
        color: _.get(options, 'lineColor', ''),
        size: _.get(options, 'markerSize', ''),
        opacity: _.get(options, 'isTransparent', false) ? 0.5 : '',
      },
      meta: _.get(options, 'meta', {}),
      customData: dataItems,
      // meta: dataItems,
      mode: 'markers'
    };
  }
  else {
    trace = {
      line: {
        dash: _.get(options, 'lineDash', 'solid'),
        color: _.get(options, 'lineColor', ''),
        width: _.get(options, 'lineWidth', '')
      },
      meta: _.get(options, 'meta', {}),
      customData: dataItems,
      // meta: dataItems,
      mode: 'lines+markers'
    };
  }
  trace.x = x;
  trace.y = y;
  trace.text_values = text_values;
  trace.hoverinfo = 'none';
  trace.isYearOnYear = isYearOnYear;
  trace.visible = _.get(options, 'visible', true) ? true : 'legendonly';
  trace.isProjection = isProjectionEnabled;
  trace.ProjectionType = projectionType;
  trace.weekPeriodLabel = weekPeriodLabel;

  return trace;
};

export const getXaxisLabelByPeriod = (dateItem, options) => {
  const entryPeriod = _.get(dateItem, 'period');
  const quarterText = _.get(dateItem, 'quarterText');
  const weekText = _.get(dateItem, 'periodLabel');
  let { periodType, isForecastingView} = options;
  const entryPeriodMmt = moment(entryPeriod)
  const entryPeriodDateObj = new Date(entryPeriodMmt.format(DATE_TIME_FORMAT));
  const isQuarterType = _.get(options, 'axisGranularity', '') == 'quarter';
  periodType = periodType || 'month';

  if(_.includes(['year','month','week','day'], periodType)) {
    return isForecastingView && isQuarterType ? quarterText : entryPeriodDateObj;
  } else if (periodType == 'quarter') {
    return isForecastingView ? quarterText : entryPeriodDateObj;
  } else if (periodType == 'week') {
    return isForecastingView ? weekText : entryPeriodDateObj;
  } else {
    return undefined;
  }
}

export const getWeekOrQuarterLabelByPeriod = (dateItem, options) => {
  const entryPeriod = _.get(dateItem, 'period');
  const quarterText = _.get(dateItem, 'quarterText');
  let { periodType, dateRange, isProjectionEnabled, isForecastingView } = options;

  switch (periodType) {
    case 'month':
    case 'quarter':
      return quarterText;
    case 'week':
      return getFormattedWeekPeriod(entryPeriod, dateRange,
        (isProjectionEnabled || isForecastingView));
    default:
      return undefined;
  }
}

const getNegativeAndAdjustMarker = (traces, formattedData, markerOptions, isBurnup) => {
  const {
    totalTraceColor,
    totalMeta,
    dataUnit,
    isSecondsFormat,
    commonTraceOptions,
    vizOptions
  } = markerOptions;

  if(_.isEmpty(formattedData)){
    return
  }
  let formattedDataTotal = _.cloneDeep(_.get(formattedData, 'current.total', []));
  if (!isBurnup){
    formattedDataTotal.push(_.cloneDeep(_.get(formattedData, 'tailingDrop.total', [])));
    formattedDataTotal = _.flatten(formattedDataTotal);
  }

  updateAdjustedValueForData(formattedDataTotal, vizOptions, 'total');
  const {
    adjustFormatted,
    negativeAndGapFormatted
  } = getAdjustedDataAndNegativeData(formattedDataTotal, vizOptions);
  const optionConfig = _.merge({}, commonTraceOptions,
    { renderType: 'markers', markerSize: 15, isProjection: false, isDiscreteMode: false }
  )

  if (!_.isEmpty(adjustFormatted) && !isBurnup) {
    traces.push(toLineTrace(adjustFormatted, 'value', {
      meta: _.merge({}, totalMeta, { value: 'primary', isProjection: false }),
      lineColor: totalTraceColor,
      lineDash: 'dot',
      visible: true,
      isSecondsFormat,
      dataUnit,
      isTransparent: true,
      ...optionConfig
    }))
  }

  if (!_.isEmpty(negativeAndGapFormatted)) {
    const traceValue = isBurnup ? 'accumulatedValue' : 'value';
    traces.push(toLineTrace(negativeAndGapFormatted, traceValue, {
      meta: _.merge({}, totalMeta, { value: 'primary', isProjection: false }),
      lineColor: '#b50404',
      lineDash: 'dot',
      visible: true,
      isSecondsFormat,
      dataUnit,
      isTransparent: true,
      ...optionConfig
    }))
  }
}

const getDummyXAxisForecast = (traces, formattedData, markerOptions) => {
  const { totalMeta, dataUnit, totalTraceColor,
    isSecondsFormat, commonTraceOptions } = markerOptions;
  _.each(formattedData.projection.forecastingProjections, (forecastProjection) => {
    const projection = _.get(forecastProjection, 'data', []);
    traces.push(toLineDummyTrace(projection, 'value', {
      meta: _.merge({}, totalMeta, { value: 'primary', isProjection: false, dimension: null }),
      lineColor: totalTraceColor,
      lineDash: 'dot',
      visible: true,
      isSecondsFormat,
      dataUnit,
      ...commonTraceOptions
    }));
  });
}

export const toLineDummyTrace = (dataItems, valueKey = 'value', options = {}) => {
  const { isYearOnYear } = options;

  const y = _.map(dataItems, (dataItem) => {
    return _.isNull(dataItem[valueKey]) ? null : Number(dataItem[valueKey]);
  });

  const x = _.map(dataItems, (dataItem) => {
    return getXaxisLabelByPeriod(dataItem, options);
  });

  const periodType = _.get(options, 'periodType');
  const isQuarterType = _.get(options, 'axisGranularity', '') == 'quarter';
  let weekPeriodLabel = [];

  if(periodType == 'week' || isQuarterType){
    weekPeriodLabel = _.map(dataItems, (dataItem) => {
      return getWeekOrQuarterLabelByPeriod(dataItem, options);
    });
  }

  let trace = {
    line: {
      dash: _.get(options, 'lineDash', 'solid'),
      color: _.get(options, 'lineColor', ''),
      width: _.get(options, 'lineWidth', '')
    },
    meta: _.get(options, 'meta', {}),
    customData: dataItems,
    mode: 'markers'
  };

  trace.x = x;
  trace.y = y;
  trace.hoverinfo = 'none';
  trace.isYearOnYear = isYearOnYear;
  trace.visible = _.get(options, 'visible', true) ? true : 'legendonly';
  trace.weekPeriodLabel = weekPeriodLabel;

  return trace;
};

const totalLineOptions = (vizOptions, formattedData, totalTraceColor) => {
  let dimensionConfigs = _.get(vizOptions, 'dimensionConfigs', []);
  const isForecastingView = _.get(vizOptions, 'isForecastingView', false);
  if (_.isEmpty(dimensionConfigs)) {
    dimensionConfigs = _.get(formattedData, 'dimensionConfigs', []);
  }
  const totalLineData = _.find(dimensionConfigs, { traceId: DEFAULT_TOTAL_NAME  });
  const totalColor = _.get(totalLineData, 'color') || totalTraceColor;
  return {
    showTotalTrace: !_.isEmpty(totalLineData) || isForecastingView,
    totalColor
  }
};